.main-content {
    padding: get-space(v, large) 0rem;
    
    p { 
        a {
            color: darken($secondary-color, 10%);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    h1, h2, h3, h4 {
        color: $secondary-color;

        a {
            color: $secondary-color;
            transition: all 200ms ease-out;

            &:hover {
                color: darken($secondary-color, 10%);
            }
        }
    }

    h2 {
        padding-top: get-space(v);
    }

    ul:not([class]) {
        padding-left: get-space(h, x-large);
      
        li {      
            position: relative;
            &:before {
                content: "•";
                position: absolute;
                left: rem-calc(-30);
                color: $secondary-color;
            }
        }
    }
}