html, body {
  font-family: $page-font;
  font-size: 100%;
  overflow-x: hidden;
}

body {
  background-color: $white-color;
  color: $primary-color;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}


* { box-sizing: border-box; }
*::after { box-sizing: border-box; }
*::before { box-sizing: border-box; }

::selection {
  background-color: fade-out($primary-color, 0.7);
  color: $primary-color;
}

::-moz-selection { // stylelint-disable-line
  background-color: fade-out($primary-color, 0.7);
  color: $primary-color;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px $primary-color inset;
  -webkit-text-fill-color: $white-color !important;
}

img, svg {
  max-width: 100%;
  display: inline-block;
  height: auto;
}

a {
  text-decoration: none; 
}

.messages{
  margin-bottom: rem-calc(30);
}
