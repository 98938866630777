.item-list {
    .pager {

        text-align: right;

        &-current {
            padding: rem-calc(12) rem-calc(20) !important;
            background-color: $secondary-color;
        }

        li {
            margin: 0;
            padding: 0;

            a {
                padding: rem-calc(10) rem-calc(20);
                text-transform: uppercase;
                transition: all 0.2s ease-out;
                font-weight: 400;
                display: inline-block;
                text-align: center;
                background-color: $gray-light;
                
                font-size: 0.9375rem;

                color: $primary-color;

                &:hover {
                    color: $white-color;
                    background-color: $primary-color;
                }
            }
        }
    }
}