.btn {

  border-style: solid;
  border-width: rem-calc(2);
  padding: rem-calc(14) rem-calc(25);
  text-transform: uppercase;
  transition: all 0.3s ease-out;
  font-weight: 400;
  display: inline-block;
  min-width: rem-calc(225);
  text-align: center;

  &--primary {
    background-color: $primary-color;
    color: $white-color;
    border-color: $primary-color;
    &:hover {
      background-color: rgba($primary-color, 0.9);
    }
  }

  &--secondary {
    background-color: $secondary-color;
    color: $white-color;
    border-color: $secondary-color;

    &:hover {
      background-color: rgba($secondary-color, 0.9);
    }
  }

  &--hollow {
    border-color: $white-color;
    color: $white-color;
    background-color: transparent;

    &:hover {
      color: $secondary-color;
      background: $white-color;
    }
  }

  &--primary-hollow {
    border-color: $primary-color;
    color: $primary-color;
    background-color: transparent;

    &:hover {
      color: $white-color;
      background: $primary-color;
    }
  }

  &--icon {
    &:before {
      font-family: "icons";
      display: inline-block;
    }
  }
}