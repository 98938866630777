.nav {
    z-index: 999; 
    @include breakpoint(md) {
        margin-right: rem-calc(20);
    }

    @include breakpoint(sm, down) {
        position: fixed;
        width: 100%;
        top: 0;
        left: 100%; 
        right: 0;
        bottom: 0;
        background: rgba(#fff, 0.95);
        padding: rem-calc(32);
        padding-top: rem-calc(70);
        transition: all .3s;

        @include breakpoint(sm) {
            width: 40%;
        }

        &.open {
            left: 0%;

            @include breakpoint(sm) {
                left: 60%;
            }
        }
    }

    &__btn {
        position: absolute;
        top : rem-calc(20);
        right: rem-calc(20);
        font-size: rem-calc(32);
        cursor: pointer;
        z-index: 999;

        @include breakpoint(md) {
            display: none; 
        }

        &.open {
            position: fixed;
        }
    }

    .menu {
        display: flex;
        list-style: none; 
        justify-content: flex-end;

        @include breakpoint(sm, down) {
            flex-direction: column;
            width: 100%;
        }

        .leaf {
            list-style: none;

            @include breakpoint(md, down) {
                margin: 0;
            }

            a {
                display: block;
                position: relative;
                color: $primary-color;
                text-transform: uppercase;
                font-size: rem-calc(15); 
                font-weight: 700;
                padding: rem-calc(12) rem-calc(14);
                transition: all 200ms ease-out;

                &:hover,
                &.active-trail {
                    background-color: $primary-color;
                    color: $white-color;
                }
            }
        }
    }
}