#social-icons {
    position: fixed;
    top: rem-calc(300);
    right: rem-calc(20);
    
    z-index: 10;

    @include breakpoint(sm, down) {
        display: none;
    }

    .link {
        display: block; 
        width: rem-calc(46);
        height: rem-calc(46);
        background-color: $primary-color;
        color: $white-color;
        font-size: rem-calc(24);
        text-align: center;
        line-height: rem-calc(50);
        margin: rem-calc(8) 0rem;

        &:hover {
            background-color: lighten($primary-color, 10%);
        }
    }
}