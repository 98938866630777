
.main-content { 
    .front & {
        h1 {
            color: $primary-color;
            padding: get-space(v, x-large) 0rem;
        }

        .row--services {
            > div > p {
                position: relative; 
                margin-bottom: rem-calc(40);
                font-size: rem-calc(15);

                &:after {
                    content: "";
                    left: 0px;
                    bottom: rem-calc(-16);
                    width: rem-calc(24);
                    height: rem-calc(2);
                    background-color: $secondary-color;
                    position: absolute;
                }
            }
        }
    }
}