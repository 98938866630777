footer, .footer {

    h1, h2, h3, h4 {
        color: $secondary-color;
    }

    &__content {
        font-size: rem-calc(15);

        padding: get-space(v, large) 0rem;
        margin: 0;
        border-top: 9px solid $secondary-color;

        &--left {
            @include breakpoint(md, down){
                text-align: center;
            }
        }

        &--right {
            @include breakpoint(sm, down){
                margin-top: get-space(v, large);
            }

            .ml-custom {
                @include breakpoint(sm, down){
                    display: block;
                }

                @include breakpoint(md){
                    margin-left: get-space(h, x-large);
                }
            }
        }
    }

    #block-block-2 {
        p {
            margin: 0;
            line-height: 0;
        }
    }
}