form {
    textarea,
    input {
        padding: rem-calc(16) rem-calc(18);
        border: rem-calc(1) solid lighten($primary-color, 40%);
        font-size: rem-calc(16);
        color: $primary-color;
    }

    input {
        text-align: left;
        

        &::placeholder {
            color: $primary-color;
        }

        &.webform-submit { 
            border: none;
            padding: rem-calc(14) rem-calc(25);
            text-transform: uppercase;
            transition: all 0.2s ease-out;
            font-weight: 400;
            display: inline-block;
            text-align: center;
            background-color: $primary-color;
            color: $white-color;
            font-size: rem-calc(15);

            &:hover {
                background-color: lighten($primary-color, 10%);
            }
        }
    }
}

#webform-client-form-16 {

    

    .webform-component--udaje .fieldset-wrapper { 
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-left: -0.9375rem;
        margin-right: -0.9375rem;

        input {
            width: 100%;
        }
      }
}