.list-check {
    li {
      @include icon(checked);
      padding-left: rem-calc(45);
      position: relative;

      &:before {
        color: $primary-color;
        position: absolute;
        left: rem-calc(15);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem-calc(18);
      }
    }
  }