// Page
.node-type-aktivity {
    .field-name-field-obrazok {
        @include breakpoint(sm, down){
            img {
                width: 100%;
            }
        }
        @include breakpoint(md){
            float: left;
            margin-right: rem-calc(20);
        }
    } 
}

// View
.activity {
    margin: get-space(v) 0rem;

    &__content {

        display: flex;
        flex-direction: column;    
        justify-content: center;

        @include breakpoint(sm, down){
            margin: get-space(v) 0rem;
        }

        .title {
            padding: get-space(v, tiny) 0rem;
        }

        .created {
            font-size: rem-calc(13);
            color: lighten($primary-color, 40%);
        }
    }

    &__more a {
        
        padding: rem-calc(14) rem-calc(25);
        text-transform: uppercase;
        transition: all 0.2s ease-out;
        font-weight: 400;
        display: inline-block;
        text-align: center;
        background-color: $primary-color;
        color: $white-color;
        font-size: rem-calc(15);

        &:hover {
            background-color: lighten($primary-color, 10%);
        }
    }
}
