@font-face {
  font-family: "icons";
  src: url('../fonts/icons/icons.eot?v1');
  src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
  url('../fonts/icons/icons.woff2?v1') format('woff2'),
  url('../fonts/icons/icons.woff?v1') format('woff'),
  url('../fonts/icons/icons.ttf?v1') format('truetype'),
  url('../fonts/icons/icons.svg#icons') format('svg');
}

@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}


@function icon-char($filename) {
  $char: "";

  @if $filename == close {
  $char: "\E001";
}
  @if $filename == envelope {
  $char: "\E002";
}
  @if $filename == envelope2 {
  $char: "\E003";
}
  @if $filename == facebook {
  $char: "\E004";
}
  @if $filename == house {
  $char: "\E005";
}
  @if $filename == menu {
  $char: "\E006";
}
  @if $filename == pdf {
  $char: "\E007";
}
  @if $filename == phone {
  $char: "\E008";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @include icon-styles;
  content: icon-char($filename);
}
}

.icon-close {
  @include icon(close);
}
.icon-envelope {
  @include icon(envelope);
}
.icon-envelope2 {
  @include icon(envelope2);
}
.icon-facebook {
  @include icon(facebook);
}
.icon-house {
  @include icon(house);
}
.icon-menu {
  @include icon(menu);
}
.icon-pdf {
  @include icon(pdf);
}
.icon-phone {
  @include icon(phone);
}
