$spaces-horizontal: (
  default: rem-calc(15),
  tiny: rem-calc(5),
  small: rem-calc(10),
  large: rem-calc(30),
  x-large: rem-calc(60),
  xx-large: rem-calc(90),
);

$spaces-vertical: (
  default: rem-calc(15),
  tiny: rem-calc(5),
  small: rem-calc(10),
  large: rem-calc(30),
  x-large: rem-calc(60),
  xx-large: rem-calc(90),
);

// grid
$grid-base: 12 !default;

$gutters: (
  default: get-space('h', 'default'),
);

$gutters-form: (
  default: get-space('h', 'default'),
);

$wrapper-widths: (
    sm: rem-calc(540),
    md: rem-calc(720),
    lg: rem-calc(960),
    xl: rem-calc(1140),
);

$wrapper-paddings: (
  default: get-space('h', 'default'),
);
