%h1, %h2,
h1,.h1 {
  font-weight: 900;
  font-size:rem-calc(32);
  text-transform: uppercase;
}

h2,.h2 {
  font-size:rem-calc(22);
  text-transform: uppercase;
  font-weight: 900;
}

h3, .h3 {
  font-size: rem-calc(18);
  text-transform: uppercase;
  font-weight: 900;
}

%small,
.small {
  font-size: rem-calc(14);
}

%bigger,
.bigger {

  @include breakpoint(sm, down){
    font-size:rem-calc(16);
    line-height: rem-calc(24);
  }

  @include breakpoint(md){
    font-size:rem-calc(18);
    line-height: rem-calc(30);
  }

}

%big,
.big {

  @include breakpoint(sm, down){
    font-size:rem-calc(18);
  }

  @include breakpoint(md){
    font-size:rem-calc(22);
  }

  @include breakpoint(lg){
    font-size:rem-calc(26);
  }
}

p {
  margin: rem-calc(16) 0px;
  line-height: 1.35;
}

ul {
  line-height: 1.75;
}

.c-pink,
.c-secondary {
  color: $secondary-color !important; 
}

.c-gray,
.c-primary {
  color: $primary-color !important;
}