#breadcrumbs {
    margin-top: get-space(v, tiny);

    li {
        display: inline;
        color: lighten($primary-color,  40%);
        font-size: rem-calc(13);
        margin-right: get-space(v, tiny);

        &+li:before {
            content:" / ";
            margin-right: get-space(v, tiny);
        }

        a {
            color: lighten($primary-color,  40%);
            text-decoration: underline;

            &:hover {
                color: $primary-color;
            }
        }
    }
}