#node-16 {
    .contact-info {
        margin-top: get-space(v, large);

        i[class^="icon"] {
            font-size: rem-calc(32);
            color: $secondary-color;
        }

        font-size: rem-calc(17);
        font-weight: 700;

        a {
           color: $primary-color;
           
           &:hover {
               color: $secondary-color;
           }
        }

    }
}