.header {
    

    &__top {
        align-items: center;
        background-color: $gray-light;
    }

    &__banner {
        position: relative;
        padding-top: get-space(v, x-large);  

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80%;
            background-color: $gray-light;
            z-index: -10;
        }

        .content {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: rem-calc(-25);
                left: rem-calc(-25);
                width: rem-calc(210);
                height: rem-calc(140);
                background-color: $secondary-color;
                z-index: -10;
            }

            &:after {
                content: "";
                position: absolute;
                right: rem-calc(-25);
                bottom: rem-calc(-25);
                width: rem-calc(50);
                height: rem-calc(50);
                background-color: $secondary-color;
            }
            
        }
    }

    &__title {
        background-color: $gray-light; 
        padding: get-space(v, large) 0rem get-space(v, large) 0rem;

        &--left {
            @include breakpoint(xs, only) { 
                width: 100%;
            }
            .title {
                font-weight: 800;
            }
        }

        &--right { 
            @include breakpoint(xs, only) {
                margin-top: get-space(v);
                width: 100%;
            }       
            .call {
                text-align: right;
                font-weight: 800;
            }
        } 
    }

}