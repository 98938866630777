// External libs
//@import '../../node_modules/breakpoint-sass/stylesheets/breakpoint';

// Functions
@import 'functions/media';
@import 'functions/rem-calc';
@import 'functions/get-variant-name';
@import 'functions/get-space';
@import 'functions/map-deep-get';
@import 'functions/map-next';

// Mixins
@import 'mixins/font-face';
@import 'mixins/border-radius';
@import 'mixins/shadows';

// Core
@import 'core/reset';
@import 'core/variables'; 
@import 'core/breakpoints';
@import 'core/spacing';
@import 'core/accessibility';
@import 'core/colors';
@import 'core/fonts';
@import 'core/global';
@import 'core/page-layout';
@import 'core/typography';
@import "core/utils";

// Icons
@import "icons";

// Components
@import 'components/buttons';
@import 'components/lists';
@import 'components/grid';
@import "components/form";
@import 'components/table';
@import 'components/logo';
@import 'components/navigation';
@import 'components/footer';
@import 'components/breadcrumbs';
@import 'components/pager';
@import 'components/social-icons';
@import 'components/galeria';

// Sections
@import 'sections/header.scss';
@import 'sections/front.scss';
@import 'sections/activity.scss';
@import 'sections/main-content.scss';
@import 'sections/contact.scss';
