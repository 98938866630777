.field-name-field-galeria {
    clear: both;
    padding-top: get-space(v, x-large);
    // @extend %row;

    .field-label {
        padding-top: get-space(v);
        color: $secondary-color;
        font-size: rem-calc(28);
    }

    .field-items {
        @extend %row;

        .field-item {
            position: relative;
            padding: get-space(v);

            @include breakpoint(xs) {
                @include column-size(6);
            }
            @include breakpoint(md) {
                @include column-size(3);
            }

            a {
                display: block;
                padding: - get-space(v);

                &:before {
                    content: "";
                    background-color: $secondary-color;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 0;
                    z-index: -1;
                }

                &:hover {
                    &:before {
                        opacity: 0.5;
                    }
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }

    }
} 