.logo{

  margin:0;
  line-height: 0;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;

  &__link{
    line-height: 0;
    font-size: 0;
  }

  &__img{
    width: 165px;

    @include breakpoint(md, down) {
      width: 145px;
    }
  }

  &__motto{
    margin-left: rem-calc(20);
    font-size: rem-calc(15);
    line-height: 1.25;
    font-weight: 400;
    text-transform: none;
    
    @include breakpoint(md, down){
      display: none;
    }
  }
}