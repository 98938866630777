table,
th,
td {
  border: 2px solid $white-color;
}

.tablefield-wrapper {
  overflow-x: auto;
  min-height: .01%;
}

table {
  width: 100%;
  table-layout: fixed;
  font-size: rem-calc(14);
  margin-bottom: get-space(v, large);

  th {
    background-color: $white-color;
    line-height: 1.2;
    vertical-align: middle;
    color: $primary-color;
    text-align: left;
    padding: rem-calc(14) rem-calc(20);
  }


  td {
    line-height: 1.5;
    padding: rem-calc(10) rem-calc(20);
  }
}
